(function($) {
  $(".schedule__year").unbind().click(function(e) {
    if ($(this).hasClass("schedule_year--selected")) {
      $(".schedule__year").removeClass("schedule_year--selected")
      $(".past_schedule").show()
    } else {
      // use id here to avoid collapsing upcoming engagements
      $("#schedule-highlights").
        find(".schedule__calendar").
        removeClass("collapse.show").
        addClass("collapse")
      $(".schedule__year").removeClass("schedule_year--selected")
      $(this).addClass("schedule_year--selected")
      $(".past_schedule").hide()
      $(`.schedule_date_${$(this).text().trim()}`).show()
      //TODO add in display for schedule cards
    }
  })

  $(".schedule__mobile-year a.menu__link").on('click', function(e) {
    e.preventDefault();
    $(this).parents('.operations--button').removeClass('operations--expanded');
    if (!$(this).hasClass("menu__link--selected")) {
      $("#schedule-highlights").
        find(".schedule__calendar").
        removeClass("collapse.show").
        addClass("collapse")
    }
    $(this).siblings('.menu__link--selected').removeClass('menu__link--selected');
    $(this).addClass('menu__link--selected');
  })

  // Unclaimed Profiles
  $('.notification-bar__close').on('click', function() {
    $('.performer__page-ownership').hide();
  })


  $('.biography__expand').on('click', function () {
    $(this).parents('.biography').addClass('biography--expanded');
  });

  $('.connection-link').on('click', function(e) {
    e.stopPropagation();
  });

  $('.submit-on-click').on('click', function (){
    $(this.closest('form')).submit();
  });
})(jQuery);
